import DefaultDataGrid from "@client/components/CustomerPortal/ui-components/dataGrid/DefaultDataGrid";
import { Box, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { IRoomType } from "../../../../../../types/NendaTypes";
import ScreenListOptions, {
  Option,
} from "./components/streamingContentManager/components/ScreenListOptions";
import {
  ContentManagerState,
  RoomWithContentAndType,
} from "./PremiseDashboard";

interface StreamingAppScreensListProps {
  screens: RoomWithContentAndType[];
  roomTypes: IRoomType[];
  setContentManagerState: (state: ContentManagerState) => void;
  contentManagerState: ContentManagerState;
  toggleContentManager: () => void;
  isContentManagerEnabled: boolean;
}

const StreamingAppScreensList: React.FC<StreamingAppScreensListProps> = ({
  screens,
  roomTypes,
  setContentManagerState,
  contentManagerState,
  toggleContentManager,
  isContentManagerEnabled,
}) => {
  const defaultRoomTypeCategory = (roomId: string) => {
    const roomTypeId = screens.find((room) => room._id === roomId)?.roomType;
    if (!roomTypeId) return undefined;
    const roomType = roomTypes.find((roomType) => roomType._id === roomTypeId);
    return roomType?.category;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleContentManagerWithSelection = (roomId: string) => {
    setContentManagerState({
      ...contentManagerState,
      selectedRooms: [roomId],
      selectedRoomCategory: defaultRoomTypeCategory(roomId),
      initialStep: 2,
    });
    toggleContentManager();
  };

  const columns: GridColDef<(typeof rows)[number]>[] = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "number",
        headerName: "Room No",
        width: 100,
        renderCell: (params) => (
          <Typography variant="body1" sx={{ px: "0.5rem" }}>
            {params.value}
          </Typography>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => (
          <Typography variant="body1">{params.value}</Typography>
        ),
      },
      {
        field: "packages",
        headerName: "Current Package",
        sortable: false,
        flex: 1,
        renderCell: (params) =>
          params.value.map((val) =>
            isMobile ? (
              <Typography key={params.row.id + val} sx={{ mr: "0.5rem" }}>
                {val}
              </Typography>
            ) : (
              <Chip
                key={params.row.id + val}
                sx={{ mr: "0.5rem" }}
                size="small"
                label={val}
              />
            )
          ),
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        align: "right",
        width: 100,
        renderCell: (params) => {
          return (
            <ScreenListOptions
              options={
                [
                  isContentManagerEnabled && {
                    title: "Manage Content Plan",
                    onClick: () =>
                      handleContentManagerWithSelection(params.row.id),
                  },
                ].filter(Boolean) as Option[]
              }
            />
          );
        },
      },
    ];
  }, [isMobile, screens, isContentManagerEnabled]);

  const rows = useMemo(() => {
    return screens.map((room) => {
      return {
        id: room._id,
        name: room.name,
        number: room.roomIdentifier,
        packages: room.packages.map((p) => p.name),
      };
    });
  }, [screens]);

  return (
    <Box sx={{ pb: "4rem" }}>
      <DefaultDataGrid
        columns={columns}
        rows={rows}
        sortingConfig={[{ field: "id", sort: "asc" }]}
        visibleColumns={{
          id: false,
          number: isMobile ? false : true,
        }}
      />
    </Box>
  );
};

export default StreamingAppScreensList;
