import { stringToColor } from "@client/utils/string";
import { VolumeOffOutlined, VolumeUpOutlined } from "@mui/icons-material";
import {
  Box,
  Chip,
  SvgIconProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GridColDef, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { IconEye, IconEyeOff } from "@tabler/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IContentChannel } from "../../../../../types/NendaTypes";
import ConfirmationDialog from "../../ConfirmationDialog";
import DefaultDataGrid, {
  RowOption,
} from "../../ui-components/dataGrid/DefaultDataGrid";

interface ChannelListProps {
  channels: IContentChannel[];
  onDelete: (channelId: string) => void;
}

const ChannelList: React.FC<ChannelListProps> = ({ channels, onDelete }) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [selectedChannel, setSelectedChannel] = React.useState<string>("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const grayIconProps: SvgIconProps = {
    sx: { cursor: "default", ml: 1, color: "#2c2c2c" },
    fontSize: "small",
  };

  const { t } = useTranslation();
  const handleDelete = (isConfirmed) => {
    if (isConfirmed) {
      onDelete(selectedChannel);
    }
    setShowDeleteDialog(false);
  };

  const handleShowDeleteDialog = (channelId: string) => {
    setSelectedChannel(channelId);
    setShowDeleteDialog(true);
  };

  const channelNameById = (id: string) => {
    const channel = channels.find((channel) => channel._id === id);
    return channel ? channel.name : "";
  };

  const rows: GridRowsProp = [...channels].map((channel) => {
    return {
      id: channel._id,
      name: channel.name,
      numberOfAssets: channel.playlist.length,
      sortOrder: channel.sortOrder,
      category: channel.category,
      hasSound: channel.hasSound,
      isPublic: channel.isPublic,
    };
  });

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      flex: 1,
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              color: theme.palette.primary.dark,
              cursor: "pointer",
            }}
            onClick={() => navigate(`/admin/content/${params.row.id}/`)}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      renderCell: (params) => (
        <Chip
          sx={{ backgroundColor: stringToColor(params.row.category) + "55" }}
          label={params.row.category}
        />
      ),
    },
    {
      field: "sortOrder",
      headerName: "Sort order",
      width: 150,
      renderCell: (params) => (
        <Typography variant="body1">{params.row.sortOrder}</Typography>
      ),
    },
    {
      field: "hasSound",
      headerName: "Sound",
      renderHeader: () => (
        <Box sx={{ display: "flex", pl: "0.5rem", alignItems: "center" }}>
          <Typography variant="body2">{t("common.sound")}</Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isOn = params.row.hasSound;
        return (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              opacity: isOn ? 1 : 0.2,
            }}
          >
            {isOn ? (
              <VolumeUpOutlined {...grayIconProps} />
            ) : (
              <VolumeOffOutlined {...grayIconProps} />
            )}
            <Box>
              <Typography>{isOn ? t("common.on") : t("common.off")}</Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "isPublic",
      headerName: "Public",
      renderHeader: () => (
        <Box sx={{ display: "flex", pl: "0.5rem", alignItems: "center" }}>
          <Typography variant="body2">{t("common.public")}</Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isPublic = params.row.isPublic;
        return (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              opacity: isPublic ? 1 : 0.2,
            }}
          >
            {isPublic ? (
              <IconEye {...grayIconProps} width={"18px"} />
            ) : (
              <IconEyeOff {...grayIconProps} width={"18px"} />
            )}
            <Box>
              <Typography>
                {isPublic ? t("common.yes") : t("common.no")}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "numberOfAssets",
      headerName: t(
        "customerportal.pages.digital_signage.list.header.num_of_assets.title"
      ) as string,
      width: 150,
      renderCell: (params) => (
        <Typography variant="body1">{params.row.numberOfAssets}</Typography>
      ),
    },
  ];

  const rowOptions = (row: GridValidRowModel): RowOption[] => [
    {
      label: t("common.edit"),
      value: "edit",
      onClick: () => {
        navigate(`/admin/content/${row.id}/`);
      },
    },
    {
      label: t("common.delete"),
      value: "delete",
      onClick: () => handleShowDeleteDialog(row.id),
    },
  ];
  return (
    <Box>
      <ConfirmationDialog
        title={t(
          "customerportal.pages.content_channels.delete_channel_confirmation_title",
          {
            channelName: channelNameById(selectedChannel),
          }
        )}
        onClose={handleDelete}
        content={t(
          "customerportal.pages.content_channels.delete_channel_confirmation_description"
        )}
        isOpen={showDeleteDialog}
      />
      <Box>
        <DefaultDataGrid
          columns={columns}
          rows={rows}
          sortingConfig={[{ field: "sortOrder", sort: "asc" }]}
          visibleColumns={{
            id: false,
            hasSound: isMobile ? false : true,
            isPublic: isMobile ? false : true,
            numberOfAssets: isMobile ? false : true,
            sortOrder: isMobile ? false : true,
          }}
          rowOptions={rowOptions}
        />
      </Box>
    </Box>
  );
};

export default ChannelList;
