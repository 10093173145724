import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowsProp,
  GridSortModel,
  GridToolbar,
  GridValidRowModel,
} from "@mui/x-data-grid";
import OptionsButton from "../buttons/OptionsButton";

type DefaultDataGridProps = {
  rows: GridRowsProp;
  columns: GridColDef[];
  sortingConfig?: GridSortModel;
  visibleColumns?: GridColumnVisibilityModel;
  rowOptions?: (
    row: any
  ) => { label: string; value: string; onClick: (row: any) => void }[];
};

export type RowOption = {
  label: string;
  value: string;
  onClick: (row: GridValidRowModel) => void;
};
const DefaultDataGrid = (props: DefaultDataGridProps) => {
  const { rows, columns, sortingConfig, visibleColumns, rowOptions } = props;

  const enhancedColumns: GridColDef[] = rowOptions
    ? [
        ...columns,
        {
          field: "actions",
          headerName: "",
          align: "right",
          disableColumnMenu: true,
          headerAlign: "right",
          renderCell: (params) => (
            <OptionsButton
              options={rowOptions(params.row).map((action) => {
                return {
                  label: action.label,
                  value: action.value,
                  onClick: () => action.onClick(params.row.id),
                };
              })}
            />
          ),
        },
      ]
    : columns;

  return (
    <>
      <DataGrid
        autoHeight
        columnBufferPx={100}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: false,
            },
          },
          sorting: {
            sortModel: sortingConfig,
          },
        }}
        ignoreDiacritics={true}
        disableColumnSelector
        disableDensitySelector
        columnVisibilityModel={visibleColumns}
        columns={enhancedColumns}
        rows={rows}
        slots={{ toolbar: GridToolbar }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
      />
    </>
  );
};

export default DefaultDataGrid;
