import {
  getCompanySignagePlaylists,
  getPremiseSignagePlaylists,
  selectSignagePlaylistsByNavigationLevel,
} from "@client/components/CustomerPortal/store/reducers/signagePlaylistReducer";
import { selectNavigationScope } from "@client/components/CustomerPortal/store/reducers/workspaceReducer";
import RadioButtonCard from "@client/components/CustomerPortal/ui-components/buttons/RadioButtonCard";
import { capitalizeString } from "@client/utils/string";
import {
  Box,
  Checkbox,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import constants from "@shared/constants";
import { t } from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Company,
  IOrganizationUnit,
  IRoom,
  ISignagePromotion,
  NendaProduct,
  Resource,
  ScheduleContentRefType,
  Scope,
  ScreenOrientation,
  SignageSlotAmountValues,
} from "../../../../../../types/NendaTypes";
import { CustomerPortalState, store } from "../../../store";
import { getCompany } from "../../../store/reducers/companyReducer";
import {
  GetContentChannels,
  selectContentChannels,
} from "../../../store/reducers/contentChannelReducer";
import { selectPremiseById } from "../../../store/reducers/organizationUnitReducer";
import InputSelect from "../../../ui-components/input/InputSelect";
import TextInput from "../../../ui-components/input/TextInput";
import PermissionGate from "../../PermissionGate";
import { ScreenInfoType } from "../premise/EditScreenDialog";
import PromotionSelect from "./PromotionSelect";
import LandscapeIcon from "../../../assets/images/svg/screen-landscape-icon.svg";
import PortraitIcon from "../../../assets/images/svg/screen-portrait-icon.svg";

interface EditScreenProps {
  screen: IRoom;
  screenInfoState: ScreenInfoType;
  setScreenInfoState: (value: ScreenInfoType) => void;
  availablePromotions: ISignagePromotion[];
  onPromotionChange: (selectedPromotions: string[]) => void;
  onNumberOfScreensChange: (numberOfScreens: number) => void;
  numberOfScreens: number | undefined;
}

const EditScreen: React.FC<EditScreenProps> = ({
  screen,
  screenInfoState,
  setScreenInfoState,
  onPromotionChange,
  onNumberOfScreensChange,
  availablePromotions,
  numberOfScreens,
}) => {
  const contentChannels = useSelector(selectContentChannels);

  const { name, description, soundEnabled } = screenInfoState;

  const premise = useSelector((state: CustomerPortalState) =>
    selectPremiseById(state, screen.hotel)
  );

  const filteredChannels = contentChannels.filter((cc) =>
    premise?.contentChannels?.some((pcc) => pcc === cc._id)
  );
  const handleNumberOfScreensChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newNumber = parseInt(e.target.value);
    if (isNaN(newNumber)) {
      return;
    }

    onNumberOfScreensChange(newNumber);
  };

  const promotionsEnabled =
    premise?.signageConfig?.arePromotionsEnabled &&
    screen.signage.config?.arePromotionsEnabled;

  const company = useSelector((state: CustomerPortalState) => {
    if (premise)
      return getCompany(
        state,
        premise.company instanceof Object
          ? premise.company._id
          : premise.company
      );
    return undefined;
  });

  useEffect(() => {
    store.dispatch(GetContentChannels());
  }, [premise, company]);

  const signagePlaylists = useSelector(selectSignagePlaylistsByNavigationLevel);
  const scope = useSelector(selectNavigationScope);

  useEffect(() => {
    if (scope.company) {
      store.dispatch(getCompanySignagePlaylists(scope.company));
    }
    if (scope.premise) {
      store.dispatch(getPremiseSignagePlaylists(scope.premise));
    }
  }, [scope.company, scope.premise]);

  const contentChannelItems = filteredChannels.map((c) => ({
    id: c._id,
    name: c.name,
    type: ScheduleContentRefType.CONTENT_CHANNEL,
  }));
  const signageAsContentEnabled =
    premise?.signageConfig?.isSignageAsContentEnabled;
  const signagePlaylistItems = signageAsContentEnabled
    ? signagePlaylists.map((c) => ({
        id: c._id,
        name: c.name,
        type: ScheduleContentRefType.SIGNAGE_PLAYLIST,
      }))
    : [];

  const hasEffectProduct = screen.nendaProducts.includes(NendaProduct.SIGNAGE);
  const theme = useTheme();

  const handleOrientationChange = (orientation: ScreenOrientation) => {
    setScreenInfoState({ ...screenInfoState, screenOrientation: orientation });
  };
  return (
    <Box>
      <Stack direction={"column"}>
        <TextInput
          fullWidth
          id="screen-name"
          label={t("Name")}
          variant="standard"
          placeholder={name}
          value={name}
          onChange={(e) =>
            setScreenInfoState({ ...screenInfoState, name: e.target.value })
          }
        />
        <TextInput
          fullWidth
          id="screen-description"
          label={t("Description")}
          variant="standard"
          placeholder={description}
          value={description}
          onChange={(e) =>
            setScreenInfoState({
              ...screenInfoState,
              description: e.target.value,
            })
          }
        />
        {
          <PermissionGate
            restriction={{
              resource: Resource.Premise,
              scopes: [Scope.CanAdministrate],
            }}
          >
            <TextInput
              fullWidth
              id="number-of-connected-screens"
              label={t(
                "customerportal.pages.settings.screens.number_of_screens"
              )}
              variant="standard"
              type="number"
              value={numberOfScreens}
              onChange={handleNumberOfScreensChange}
            />
          </PermissionGate>
        }
        <Stack direction={"column"} spacing={"1rem"}>
          {hasEffectProduct && promotionsEnabled && (
            <InputSelect
              id="signage-slots-select"
              value={
                typeof screenInfoState.signageSlotsPerHour === "number"
                  ? screenInfoState.signageSlotsPerHour
                  : getInheritedSignageSlotValue({ premise, company })
              }
              title={`${t(
                "customerportal.pages.settings.screens.signage_slots_per_hour"
              )} (${getInheritedSignageSlotValue({
                premise,
                company,
              })} default)`}
              variant="outlined"
              onChange={(e) =>
                setScreenInfoState({
                  ...screenInfoState,
                  signageSlotsPerHour: e.target.value,
                })
              }
            >
              {SignageSlotAmountValues.map((signageSlot, index) => (
                <MenuItem key={index} value={signageSlot}>
                  {signageSlot}
                </MenuItem>
              ))}
            </InputSelect>
          )}
          {hasEffectProduct && promotionsEnabled && (
            <PromotionSelect
              activePromotions={screenInfoState.promotions}
              onChange={onPromotionChange}
              availablePromotions={availablePromotions}
            />
          )}
          {(filteredChannels || signagePlaylists) && hasEffectProduct && (
            <Box>
              <InputSelect
                id="channel-select"
                value={screenInfoState.defaultContent || ""}
                title={t(
                  "customerportal.pages.dashboard.screen.content.timeline.interval_type.default"
                )}
                variant="outlined"
                onChange={(e) => {
                  const id = e.target.value;

                  const item = [
                    ...signagePlaylistItems,
                    ...contentChannelItems,
                  ].find((item) => String(item.id) === id);

                  const type = item?.type;

                  if (!type) {
                    return;
                  }

                  setScreenInfoState({
                    ...screenInfoState,
                    defaultContent: id,
                    defaultContentRefType: type,
                  });
                }}
              >
                <ListSubheader
                  sx={{
                    color: theme.palette.text.primary,
                    background: theme.palette.grey[100],
                  }}
                >
                  {t("common.content_channels")}
                </ListSubheader>
                {contentChannelItems.map((content, index) => (
                  <MenuItem key={index} value={String(content?.id)}>
                    {capitalizeString(content?.name || "")}
                  </MenuItem>
                ))}
                {signageAsContentEnabled && (
                  <ListSubheader
                    sx={{
                      color: theme.palette.text.primary,
                      background: theme.palette.grey[100],
                    }}
                  >
                    {t("common.digital_signage")}
                  </ListSubheader>
                )}
                {signagePlaylistItems.map((content, index) => (
                  <MenuItem key={index} value={String(content?.id)}>
                    {capitalizeString(content?.name || "")}
                  </MenuItem>
                ))}
              </InputSelect>
            </Box>
          )}
        </Stack>
        {hasEffectProduct && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={soundEnabled}
                  id="screen-sound-enabled"
                  onChange={() =>
                    setScreenInfoState({
                      ...screenInfoState,
                      soundEnabled: !soundEnabled,
                    })
                  }
                />
              }
              label={t("Sound Enabled")}
            />

            <Box sx={{ my: "1rem" }}>
              <Typography variant="h6">
                {t(
                  "customerportal.pages.dashboard.screen.edit_screen.screen_orientation"
                )}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <RadioButtonCard
                selected={
                  screenInfoState.screenOrientation ===
                  ScreenOrientation.LANDSCAPE
                }
                title={t(
                  "customerportal.pages.dashboard.screen.edit_screen.landscape"
                )}
                description={t(
                  "customerportal.pages.dashboard.screen.edit_screen.landscape_description"
                )}
                icon={<LandscapeIcon />}
                onClick={() =>
                  handleOrientationChange(ScreenOrientation.LANDSCAPE)
                }
                maxWidth="400px"
              />
              <RadioButtonCard
                selected={
                  screenInfoState.screenOrientation ===
                  ScreenOrientation.PORTRAIT
                }
                title={t(
                  "customerportal.pages.dashboard.screen.edit_screen.portrait"
                )}
                description={t(
                  "customerportal.pages.dashboard.screen.edit_screen.portrait_description"
                )}
                icon={<PortraitIcon />}
                onClick={() =>
                  handleOrientationChange(ScreenOrientation.PORTRAIT)
                }
                maxWidth="400px"
              />
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default EditScreen;

export const getInheritedSignageSlotValue = ({
  premise,
  company,
}: {
  premise?: IOrganizationUnit;
  company?: Company;
}) => {
  if (premise?.signageConfig?.numberOfHourlySignageSlots) {
    return premise?.signageConfig?.numberOfHourlySignageSlots;
  }

  if (company?.signageConfig?.numberOfHourlySignageSlots) {
    return company?.signageConfig?.numberOfHourlySignageSlots;
  }

  const { SIGNAGE_SLOTS_PER_HOUR } = constants.Signage();
  return SIGNAGE_SLOTS_PER_HOUR;
};
